<ol class="breadcrumb page-breadcrumb" *transloco="let translated">
  @for (item of this.breadcumbs; track $index) {
    @if (this.enableLanguage) {
      <li class="breadcrumb-item" [class.active]="$index == this.activeIndex">{{translated(item)}}</li>
    } @else {
      <li class="breadcrumb-item" [class.active]="$index == this.activeIndex">item</li>
    }
  }
  <li class="position-absolute pos-top pos-right d-none d-sm-block">
    <span class="js-get-date">
      {{ this.displayDay }}
    </span>
  </li>
</ol>
