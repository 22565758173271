import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

@Component({
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  selector: 'app-zaa-breadcumb',
  templateUrl: './zaa-breadcumb.component.html',
  styleUrls: ['./zaa-breadcumb.component.css']
})
export class ZaaBreadcumbComponent implements OnInit {
  @Input() breadcumbs: Array<string> = [];
  @Input() enableLanguage: boolean = false; // Enable language - each breadcumbs is key path.
  @Input() activeIndex: number = 0;

  languageService = inject(TranslocoService);
  displayDay: string = '';
  constructor() { }

  ngOnInit() {
    const today = new Date();
    const activeLanguage = this.languageService.getActiveLang();
    if (activeLanguage == 'es') {
      this.displayDay = today.toLocaleDateString('en', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    } else {
      this.displayDay = today.toLocaleDateString('vi', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    }
  }

}
